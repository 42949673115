import React from "react"
import { Link } from 'gatsby'
import pageStyles from "./pages.module.css"

const ThanksPage = () => {
  return (
      <div className={pageStyles.page}>
          <h2>Thanks for your message</h2>
          <p>Return to  <Link to="/">home page</Link>!</p>
      </div>  
  )
}
export default ThanksPage